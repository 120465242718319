<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh sách kiện hàng</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-content" style="padding-top:10px;">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th style="width: 400px;">Hành trình</th>
                                        <th style="width: 150px;">Kiện hàng</th>
                                        <th style="width: 200px;">Tên hàng</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && data.length > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.goodsRoute.placeOfGo.locationName}} => {{item.goodsRoute.placeOfCome.locationName}}</a></td>
                                        <td><a @click="selected(item)">{{item.orderDetail.serial}}</a></td>
                                        <td><a @click="selected(item)">{{item.orderDetail.goods.goodsName}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import jobService from '../../api/jobService'; 
    import messageBox from '../../utils/messageBox';

    export default {
        data: () => ({
            showDialog: false,
            data: [],
            loadding: false
        }),

        created() {
        },

        methods: { 
            selected(item){
                this.$emit('close', item);
            },

            onChange: function () {
                this.getData();
            },

            open(jobId){
                this.getData(jobId);
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            getData(jobId){
                this.loadding = true;
                jobService.getById(jobId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = [];
                        for(let i = 0; i < response.data.jobRoutes.length; i++){
                            let obj = response.data.jobRoutes[i];
                            let item = { goodsRoute: obj.goodsRoute, orderDetail: obj.orderDetail, goodsId: obj.goodsId, goodsRouteId: obj.goodsRouteId, jobId: obj.jobId, orderId: obj.orderId, orderDetailId: obj.orderDetailId };
                            this.data.push(item);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        },
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 800px;
        height: 455px;
        max-width: 800px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>