<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button :disabled="entity.status > 1 && entity.status != 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 1" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="jobName" @md-selected="getJobSelected" :md-options="jobs" @md-changed="getJobs"  @md-opened="getJobs" :class="{'md-invalid': submitted && $v.entity.jobId.$error }">
                                    <label>Điều động phương tiện</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.jobCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.jobId.isSelected">Vui lòng chọn lệnh điều động phương tiện</span>
                                </md-autocomplete>
                                <md-button @click="openJob()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm lệnh điều động phương tiện</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                               <label for="code">Mã dự toán</label>
                               <md-input name="code" v-model="entity.estimateCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập mã dự toán</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.estimateDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.estimateDate.$error }">
                                <label>Ngày dự toán</label>
                                <span class="md-error" v-if="submitted && !$v.entity.estimateDate.required">Vui lòng chọn ngày dự toán</span>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="note">Ghi chú</label>
                                <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="note">Tổng dự toán</label>
                                    <md-input name="note" :value="formatNumber(entity.pay)" ></md-input>
                                </md-field>
                                <md-field>
                                    <label for="note">Chi phí</label>
                                    <md-input name="note" :value="formatNumber(entity.payFee)" ></md-input>
                                </md-field>
                                <md-field>
                                    <label for="note">Chi hộ</label>
                                    <md-input name="note" :value="formatNumber(entity.payOnBehalf)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-order" class="tab-content" md-label="Đơn hàng">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar"></div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th style="width:35%;">Đơn hàng</th> 
                                                            <th style="width:20%;">Phí dịch vụ</th> 
                                                            <th style="width:20%;">Thu hộ</th>
                                                            <th style="width:20%;">Tổng phí</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.orders" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="left">
                                                                {{item.order.orderCode}}
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(item.payFee)}}</div>
                                                            </td> 
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(item.payOnBehalf)}}</div>
                                                            </td> 
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(item.pay)}}</div>
                                                            </td> 
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-detail" class="tab-content" md-label="Chi phí chung">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:10%">Hành động</th>
                                                            <th style="width:10%;">Đơn hàng</th> 
                                                            <th style="width:10%;">Mã phí</th> 
                                                            <th style="width:22%;">Tên phí</th> 
                                                            <th style="width:10%;">Loại</th> 
                                                            <th style="width:10%;">Số tiền</th>
                                                            <th style="width:10%;">VAT</th>
                                                            <th style="width:15%;">Thành tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="grid-action">
                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.orderId" name="detail-order" id="detail-order">
                                                                        <md-option v-for="item in entity.orders" :key="'detail-order-' + item.orderId" :value="item.orderId">{{item.order.orderCode}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.fee.feeCode" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm phí</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="left">
                                                                {{item.fee.feeName}}
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.type" name="type" id="type">
                                                                        <md-option v-for="item in types" :key="'type-' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum(item)" :class="{'is-error': $v.entity.details.$each[index].amount.$error}" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calAmount(item)" v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div class="sum-title">Tổng</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(amount)}} VNĐ</div>
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div class="sum-title">Thuế VAT</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(vat)}} VNĐ</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="8">
                                                                <div class="sum-title">Tổng cộng</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(pay)}} VNĐ</div>
                                                            </td> 
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-goods-detail" class="tab-content" md-label="Chi phí hành trình">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addGoodsDetailRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:80px;">#</th> 
                                                            <th style="width:6%">Hành động</th> 
                                                            <th style="width:18%;">Hành trình</th>
                                                            <th style="width:15%;">Tên hàng</th> 
                                                            <th style="width:10%;">Mã phí</th> 
                                                            <th style="width:10%;">Tên phí</th> 
                                                            <th style="width:8%;">Loại</th> 
                                                            <th style="width:10%;">Số tiền</th>
                                                            <th style="width:7%;">VAT</th>
                                                            <th style="width:10%;">Thành tiền</th>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.goodsDetails" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="grid-action">
                                                                <a @click="delGoodsDetailRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control" style="justify-content: space-between;">
                                                                    <div v-if="item.goodsRouteId > 0" style="padding: 0 5px;" :class="{'is-error': $v.entity.goodsDetails.$each[index].goodsRouteId.$error}">{{item.goodsRoute.placeOfGo.locationName}} => {{item.goodsRoute.placeOfCome.locationName}}</div> 
                                                                    <div v-else></div>
                                                                    <md-button @click="openJobRoute(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm hành trình</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="left">
                                                                <div v-if="item.orderDetailId > 0">{{item.orderDetail.goods.goodsName}}</div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.fee.feeCode" :class="{'is-error': $v.entity.goodsDetails.$each[index].feeId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openFeeGoodsDetail(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm phí</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="left">
                                                                {{item.fee.feeName}}
                                                            </td>
                                                            <td class="center">
                                                                <md-field>
                                                                    <md-select v-model="item.type" name="type" id="type">
                                                                        <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                    </md-select>
                                                                </md-field>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum2(item)" :class="{'is-error': $v.entity.goodsDetails.$each[index].amount.$error}" v-model="item.amount" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calSum2(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <number @change="calAmount2(item)" v-model="item.pay" v-bind="currency" class="form-control currency"></number> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="9">
                                                                <div class="sum-title">Tổng</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(amount2)}} VNĐ</div>
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td colspan="9">
                                                                <div class="sum-title">Thuế VAT</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(vat2)}} VNĐ</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="9">
                                                                <div class="sum-title">Tổng cộng</div>
                                                            </td>
                                                            <td class="center">
                                                                <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(pay2)}} VNĐ</div>
                                                            </td> 
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:15%;">Bộ phận/phòng</th> 
                                                            <th style="width:20%;">Người phê duyệt</th> 
                                                            <th style="width:25%;">Tiêu đề</th>
                                                            <th style="width:20%;">Ghi chú</th> 
                                                            <th style="width:10%;">Thứ tự</th> 
                                                            <th style="width:150px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}" class="form-control" type="text">
                                                                    <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.title" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.note" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <jobList ref="jobList" @close="closeJob"/>
        <feeList ref="feeList" @close="closeFee"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <jobRouteList ref="jobRouteList" @close="closeJobRoute"/>
        <feeGoodsDetailList ref="feeGoodsDetailList" @close="closeFeeGoodsDetail"/>
   </div>
</template>
<script>
    import jobEstimateService from '../../../api/jobEstimateService';
    import approveSettingService from '../../../api/approveSettingService';
    import jobService from '../../../api/jobService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import jobList from '../../../components/popup/_JobList.vue';
    import feeList from '../../../components/popup/_FeeList.vue';
    import approveList from '../../../components/popup/_StaffList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import jobRouteList from '../../../components/popup/_JobRouteList.vue';
    import feeGoodsDetailList from '../../../components/popup/_FeeList.vue';

    export default ({
        components: {
            staffList,
            jobList,
            feeList,
            approveList,
            deptList,
            jobRouteList,
            feeGoodsDetailList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật dự toán chi phí vận tải'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                staffName: '',
                selectedId: 0,
                staffs: [],
                jobName: '',
                jobs: [],
                loadding: false,
                catFee: common.categoryFee.job,
                entity: { id: 0, orders: [], jobId: 0, payFee: 0, payOnBehalf: 0, goodsDetails: [], details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', isHistory: false },
                detail: { id: 'id_' + common.getFakeId(), orderId: 0, order: null, isApproved: true, estimateId: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, amount: 0, vat: 0, pay: 0, note: '' },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', typeCode: String(common.approveType.jobEstimate), orderSort: 1, status: 1, ApprovedAt: null },
                goodsDetail: { id: 'goodsDetail_' + common.getFakeId(), orderDetail: { goods: { goodsName: '' } }, estimateId: 0, feeId: 0, jobId: 0, orderId: 0, orderDetailId: '', goodsRouteId: 0, goodsId: 0, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, isApproved: true, note: '' },
                estimateOrder: { id: 'estimateOrder_' + common.getFakeId(), jobId: 0, orderId: 0, order: { orderCode: '' }, pay: 0, payFee: 0, payOnBehalf: 0},
                vatF: common.vatF,
                currency: common.currencyF,
                items: [],
                amount: 0,
                vat: 0,
                pay: 0,
                amount2: 0,
                vat2: 0,
                pay2: 0,
                types: common.typeFee
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật dự toán chi phí vận tải';
               this.getById();
            }
            else{
               this.title = 'Thêm mới dự toán chi phí vận tải';
               this.getByAccount();
               this.entity.details.push(this.detail);
               this.entity.approveList.push(this.approve);
               this.getApproveSetting();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calOrderFee(){
                for (var k = 0; k < this.entity.orders.length; k++) {
                    this.entity.orders[k].payFee = 0;
                    this.entity.orders[k].payOnBehalf = 0;
                    this.entity.orders[k].pay = 0;
                    for (var i = 0; i < this.entity.details.length; i++) {
                        if(this.entity.orders[k].orderId == this.entity.details[i].orderId){
                            if(parseFloat(this.entity.details[i].type) == 1){
                                this.entity.orders[k].payFee = parseFloat(this.entity.orders[k].payFee) + parseFloat(this.entity.details[i].pay);
                            }
                            else{
                                this.entity.orders[k].payOnBehalf = parseFloat(this.entity.orders[k].payOnBehalf) + parseFloat(this.entity.details[i].pay);
                            }
                        }
                    }
                    for (var j = 0; j < this.entity.goodsDetails.length; j++) {
                        if(this.entity.orders[k].orderId == this.entity.goodsDetails[j].orderId){
                            if(parseFloat(this.entity.goodsDetails[j].type) == 1){
                                this.entity.orders[k].payFee = parseFloat(this.entity.orders[k].payFee) + parseFloat(this.entity.goodsDetails[j].pay);
                            }
                            else{
                                this.entity.orders[k].payOnBehalf = parseFloat(this.entity.orders[k].payOnBehalf) + parseFloat(this.entity.goodsDetails[j].pay);
                            }
                        }
                    }
                    this.entity.orders[k].pay =  parseFloat(this.entity.orders[k].payFee) +  parseFloat(this.entity.orders[k].payOnBehalf);
                }
            },

            addGoodsDetailRow(){
                let item = { id: 'goodsDetail_' + common.getFakeId(), goodsRoute: {placeOfGo: {locationName: ''}, placeOfCome: {locationName: ''}}, orderDetail: { goods: { goodsName: '' } }, estimateId: 0, feeId: 0, jobId: 0, orderId: 0, orderDetailId: '', goodsRouteId: 0, goodsId: 0, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, isApproved: true, note: '' };
                this.entity.goodsDetails.push(item);
            },
            
            delGoodsDetailRow(item){
                const index = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                this.entity.goodsDetails.splice(index, 1);
            },

            closeJobRoute(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == this.selectedId);
                this.entity.goodsDetails[selected].orderDetailId = item.orderDetailId;
                this.entity.goodsDetails[selected].jobId = item.jobId;
                this.entity.goodsDetails[selected].orderId = item.orderId;
                this.entity.goodsDetails[selected].goodsId = item.goodsId;
                this.entity.goodsDetails[selected].goodsRouteId = item.goodsRouteId;
                this.entity.goodsDetails[selected].orderDetail = item.orderDetail;
                this.entity.goodsDetails[selected].goodsRoute = item.goodsRoute;
                
                this.$refs.jobRouteList.close();
            },

            openJobRoute(id){
                if(this.entity.jobId > 0){
                    this.selectedId = id;
                    this.$refs.jobRouteList.open(this.entity.jobId);
                }
                else{
                    messageBox.showWarning("Vui lòng chọn lệnh điều động phương tiện trước.");
                }
            },

            calAmount(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details[selected].amount = Math.round((100 * parseFloat(this.entity.details[selected].pay)) / (100 + parseFloat(this.entity.details[selected].vat)));
                this.calSummary();
            },

            calSum(item){
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.details[selected].amount) * (parseFloat(this.entity.details[selected].vat) / 100);
                this.entity.details[selected].pay = Math.round(parseFloat(this.entity.details[selected].amount) + vat);
                this.calSummary();
            },

            calSummary(){
                this.amount = 0;
                this.pay = 0;
                this.entity.payFee = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.amount = parseFloat(this.amount) + parseFloat(this.entity.details[i].amount);
                }
                for (var j = 0; j < this.entity.details.length; j++) {
                    this.pay = parseFloat(this.pay) + parseFloat(this.entity.details[j].pay);
                }
                this.vat = this.pay - this.amount;
                this.entity.amount = this.amount + this.amount2;
                this.entity.pay = this.pay + this.pay2;
                this.entity.vat = this.vat + this.vat2;
                this.calFee();
            },

            calAmount2(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                this.entity.goodsDetails[selected].amount = Math.round((100 * parseFloat(this.entity.goodsDetails[selected].pay)) / (100 + parseFloat(this.entity.goodsDetails[selected].vat)));
                this.calSummary2();
            },

            calSum2(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == item.id);
                let vat = parseFloat(this.entity.goodsDetails[selected].amount) * (parseFloat(this.entity.goodsDetails[selected].vat) / 100);
                this.entity.goodsDetails[selected].pay = Math.round(parseFloat(this.entity.goodsDetails[selected].amount) + vat);
                this.calSummary2();
            },

            calFee(){
                this.entity.payFee = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    if(parseFloat(this.entity.details[i].type) == 1){
                        this.entity.payFee = parseFloat(this.entity.payFee) + parseFloat(this.entity.details[i].pay);
                    }
                }
                for (var j = 0; j < this.entity.goodsDetails.length; j++) {
                    if(parseFloat(this.entity.goodsDetails[j].type) == 1){
                        this.entity.payFee = parseFloat(this.entity.payFee) + parseFloat(this.entity.goodsDetails[j].pay);
                    }
                }
                this.entity.payOnBehalf = this.entity.pay - this.entity.payFee;
                this.calOrderFee();
            },

            calSummary2(){
                this.amount2 = 0;
                this.pay2 = 0;
                for (var i = 0; i < this.entity.goodsDetails.length; i++) {
                    this.amount2 = parseFloat(this.amount2) + parseFloat(this.entity.goodsDetails[i].amount);
                }
                for (var j = 0; j < this.entity.goodsDetails.length; j++) {
                    this.pay2 = parseFloat(this.pay2) + parseFloat(this.entity.goodsDetails[j].pay);
                }
                this.vat2 = this.pay2 - this.amount2;
                this.entity.amount = this.amount + this.amount2;
                this.entity.pay = this.pay + this.pay2;
                this.entity.vat = this.vat + this.vat2;
                this.calFee();
            },

            addRow(){
                let item = { id: 'id_' + common.getFakeId(), isApproved: true,estimateId: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, amount: 0, vat: 0, pay: 0, note: '' };
                this.entity.details.push(item);
            },
            
            delRow(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
            },

            closeFee(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].fee = item;
                this.entity.details[selected].feeId = item.id;
                this.$refs.feeList.close();
                this.selectedId = '';
            },

            openFee(id){
                this.selectedId = id;
                this.$refs.feeList.open(this.catFee);
            },

            openFeeGoodsDetail(id){
                this.selectedId = id;
                this.$refs.feeGoodsDetailList.open(this.catFee);
            },

            closeFeeGoodsDetail(item){
                const selected = this.entity.goodsDetails.findIndex(x => x.id == this.selectedId);
                this.entity.goodsDetails[selected].fee = item;
                this.entity.goodsDetails[selected].feeId = item.id;
                this.$refs.feeGoodsDetailList.close();
                this.selectedId = '';
            },

            //Job
            closeJob(item){
                this.jobName = item.jobCode;
                this.entity.jobId = item.id;
                this.$refs.jobList.close();
                this.getJobById();
            },

            getJobSelected(val){
                this.entity.jobId = val.id;
                this.jobName = val.jobCode;
                this.getJobById();
            },

            openJob(){
                this.$refs.jobList.open();
            },

            getJobById(){
                this.setLoading(true);
                jobService.getById(this.entity.jobId).then((response) => {
                    if(response.statusCode == 200){
                        let job = response.data;
                        this.entity.goodsDetails = [];
                        for(let i = 0; i < job.jobRoutes.length; i ++){
                            let route = job.jobRoutes[i];
                            let item = { id: 'goodsDetail_' + common.getFakeId(), goodsRoute: route.goodsRoute, orderDetail: route.orderDetail, estimateId: 0, feeId: 0, jobId: route.jobId, orderId: route.orderId, orderDetailId: route.orderDetailId, goodsRouteId: route.goodsRouteId, goodsId: route.goodsId, fee: { feeCode: '', feeName: '' }, type: 1,  amount: 0, vat: 0, pay: 0, isApproved: true, note: '' };
                            this.entity.goodsDetails.push(item);

                            let order = { id: 'estimateOrder_' + common.getFakeId(), jobId: this.entity.jobId, orderId: route.orderId, order: route.order, pay: 0, payFee: 0, payOnBehalf: 0};
                            const index = this.entity.orders.findIndex(x => x.orderId == item.orderId);
                            if(index === -1){
                                this.entity.orders.push(order);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getJobs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                jobService.getForEstimate(search).then((response) => {
                    if(response.statusCode == 200){
                        this.jobs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCode(staffId){
                this.setLoading(true);
                jobEstimateService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.estimateCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.currentUser = response.data;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.jobEstimate).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairEstimate), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Approve
            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: '', typeCode: String(common.approveType.repairEstimate), orderSort: order, ApprovedAt: null };
                this.entity.approveList.push(item);
            },

            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

           //Sales
           closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.getCode(item.id);
                this.entity.approveList[0].title = "Người đề nghị";
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.orders.length > 0){
                    this.entity.orders.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.goodsDetails.length > 0){
                    this.entity.goodsDetails = this.entity.goodsDetails.filter(item => !(item.feeId == 0 && item.orderDetailId == 0));
                    this.entity.goodsDetails.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng thêm chi tiết dự toán");
                    return;
                }
                else{
                    this.entity.details.forEach(function(item, index, self) {
                        self[index].type = parseInt(self[index].type);
                    });
                }
                if(this.entity.approveList.length <= 1){
                    messageBox.showWarning("Vui lòng thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                jobEstimateService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/job-estimate');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                jobEstimateService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/job-estimate');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                jobEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.jobName = response.data.job.jobCode;
                        this.staffName = response.data.staff.fullName;
                        this.calSummary();
                        this.calSummary2();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/job-estimate');
            }
        },
        watch: { 
            jobName: function (val) { 
                if(val == ''){
                    this.entity.jobId = 0;
                    this.entity.orders = [];
                    this.entity.details = [];
                    this.entity.goodsDetails = [];
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                estimateCode: { required },
                jobId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                estimateDate: { required },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                details: {
                    $each: {
                        feeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        amount: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                goodsDetails: {
                    $each: {
                        goodsRouteId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        feeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        amount: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
    })
</script>
