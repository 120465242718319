import httpClient  from '../api'; 
const API_CONTROLLER = 'job/'; 


export default {
    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },
    
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    explain(data) { 
        let url = API_CONTROLLER + 'explain';
        return httpClient.post(url, data);
    },

    async getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    async updateStatus(id, status) { 
        let url = API_CONTROLLER + 'update-status';
        return httpClient.get(url, {
            params: {
                id: id,
                status: status
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                driverId: search.driverId,
                vehicleId: search.vehicleId,
                status: search.status,
                keyword: search.keyword,
                fromDate: search.fromDate,
                toDate: search.toDate,
                serial: search.serial
            }
        });
    },

    getJobs(search) { 
        let url = API_CONTROLLER + 'get-jobs';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                code: search.code
            }
        });
    },

    getForInvoice(search) { 
        let url = API_CONTROLLER + 'get-for-invoice';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                code: search.code
            }
        });
    },

    getRoutes(data) { 
        let url = API_CONTROLLER + 'get-routes';
        return httpClient.post(url, data);
    },
    
    getRouteByJobInvoiceId(jobInvoiceId) { 
        let url = API_CONTROLLER + 'get-route-by-job-invoice-id';
        return httpClient.get(url, {
            params: {
                jobInvoiceId: jobInvoiceId
            }
        });
    },
}